import React from 'react'
import { Link } from "gatsby"
import Img from 'gatsby-image'

const PostList = (props) => {

    let Posts = props.postList.allMarkdownRemark.edges

    return (
        <div>
          {Posts.map(post => (
            <article className="md:flex mt-12 max-w-6xl mx-auto" key={post.node.id}>
              <div className="hidden md:block md:w-1/3 xl:w-1/4">
                <Img fluid={post.node.frontmatter.featured_image.src.childImageSharp.fluid} alt={post.node.frontmatter.featured_image.alt} 
                  className=""></Img>
              </div>
              <div className="md:ml-12 md:w-2/3 xl:w-3/4">
                <Link to={post.node.frontmatter.path} className="font-bold mt-6 text-xl hover:text-green-500">{post.node.frontmatter.title}</Link>
                <div className="mt-2">{post.node.excerpt}</div>
              </div>
            </article>
            ))
          }
        </div>
    )
}

export default PostList