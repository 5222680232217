import React from 'react'
import { Link } from 'gatsby'

const Menu = () => (
        <nav className="list-none flex justify-center sm:items-start font-semibold xs:font-bold">
            <Link to="/" className="hover:text-green-500">Home</Link>
            <Link to="/blog" className="pl-4 xs:pl-12 hover:text-green-500">Articles</Link>
            <Link to="/contact" className="pl-4 xs:pl-12 hover:text-green-500">Contact Us</Link>
        </nav>
    )

export default Menu