import React from 'react'
import { FaTwitter, FaFacebookF, FaEnvelope, FaPhone } from 'react-icons/fa'

const ContactForm = () => {
    return (
        <section className="px-8 text-gray-900 md:flex max-w-6xl mx-auto">
          <div className="md:w-1/2 lg:w-2/5">
            <div className="flex justify-between md:block md:mx-0">
              <div className="md:flex md:items-center md:justify-start">
                <a href="tel:+13026010061" aria-label="Call Us">
                  <FaPhone className="h-8 w-8 text-blue-500 hover:text-blue-700 fill-current" />
                </a>
                <a href="tel:+13026010061" className="hidden md:block ml-4 hover:text-green-500">+1 (302) 601-0061</a>
              </div>
              <div className="md:mt-6 md:flex md:items-center md:justify-start">
                <a href="mailto://support@candfsolutions.com" aria-label="Email Us">
                  <FaEnvelope className="h-8 w-8 text-blue-500 hover:text-blue-700 fill-current" />
                </a>
                <a href="mailto://support@candfsolutions.com" className="hidden md:block ml-4 hover:text-green-500">support@candfsolutions.com</a>
              </div>
              <div className="md:mt-6 md:flex md:items-center md:justify-start">
                <a href="https://facebook.com/candfsolutions" target="_blank" rel="noopener noreferrer" aria-label="Visit our FaceBook page">
                  <FaFacebookF className="h-8 w-8 text-blue-500 hover:text-blue-700 fill-current" />
                </a>
                <a href="https://facebook.com/candfsolutions" target="_blank" rel="noopener noreferrer" className="hidden md:block ml-4 hover:text-green-500">Facebook</a>
              </div>
              <div className="md:mt-6 md:flex md:items-center md:justify-start">
                <a href="https://twitter.com/cfsolutionsllc" target="_blank" rel="noopener noreferrer" aria-label="Visit us on Twitter">
                  <FaTwitter className="h-8 w-8 text-blue-500 hover:text-blue-700 fill-current" />
                </a>
                <a href="https://twitter.com/cfsolutionsllc" target="_blank" rel="noopener noreferrer" className="hidden md:block ml-4 hover:text-green-500">Twitter</a>
              </div>
            </div>
          </div>

          <div className="md:w-1/2 lg:w-3/5 mt-8 md:mt-0">
            <form name="contact" method="POST" action="/" data-netlify="true" data-netlify-recaptcha="true" netlify-honeypot="extra">
              <label className="hidden">
                <input type="hidden" name="form-name" value="contact" />
                <input name="extra" />
              </label>
              <label className="block">
                <span className="font-semibold">Name</span><br />
                <input type="text" name="name" className="px-2 border-gray-400 border w-full md:w-3/4 lg:w-3/5 leading-loose mt-2 rounded"></input>
              </label>
              <label className="block mt-4">
                <span className="font-semibold">EMail</span><br />
                <input type="text" name="email" className="px-2 border-gray-400 border w-full md:w-3/4 lg:w-3/5 leading-loose mt-2 rounded"></input>
              </label>
              <label className="block mt-4">
                <span className="font-semibold">Phone Number</span><br />
                <input type="text" name="phone" className="px-2 border-gray-400 border w-full md:w-3/4 lg:w-3/5 leading-loose mt-2 rounded"></input>
              </label>
              <label className="block mt-4">
                <span className="font-semibold">Message</span><br />
                <textarea name="message" className="w-full h-32 px-2 border-gray-400 border leading-loose mt-2 rounded"></textarea>
              </label>
              <div data-netlify-recaptcha="true"></div>
              <button type="submit" className="block w-full md:w-auto mt-6 px-6 py-2 text-xl font-bold rounded bg-green-300 hover:bg-green-400 lg:mx-0">Submit</button>
            </form>
          </div>
        </section>
    )
}

export default ContactForm