import React from 'react'
import Menu from './menu'
import { FaTwitter, FaFacebookF } from 'react-icons/fa'

const Footer = () => {
    return (
        <footer className="bg-gray-900 text-white px-4 sm:px-8 py-6 text-center sm:flex sm:items-center sm:justify-between">
          &copy; {new Date().getFullYear()} C&amp;F Solutions, LLC
          <div className="mt-4 sm:mt-0 text-white text-sm">
            <Menu></Menu>
          </div>
          <div className="mt-4 sm:mt-0">
            <a href="https://facebook.com/candfsolutions" target="_blank" rel="noopener noreferrer" aria-label="Visit our FaceBook page">
              <FaFacebookF className="inline-block h-6 w-6 text-blue-500 hover:text-blue-300 fill-current mr-6" alt="Visit our FaceBook page"/>
            </a>
            <a href="https://twitter.com/cfsolutionsllc" target="_blank" rel="noopener noreferrer" aria-label="Visit us on Twitter">
              <FaTwitter className="inline-block h-6 w-6 text-blue-500 hover:text-blue-300 fill-current" alt="Visit us on Twitter" />
            </a>

          </div>
        </footer>
    )
}

export default Footer