import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import Img from "gatsby-image"
import BackgroundImage from "gatsby-background-image"

import Layout from "../components/layout"
import SEO from "../components/seo"
import Contact from "../components/contact_form"
import Posts from "../components/post_list"

const IndexPage = () => {
  let posts = {}
  const { markdownPosts, heroImage, meetingImage, keyboardImage} = useStaticQuery(graphql`
      query {
        markdownPosts: allMarkdownRemark(limit: 3, sort: { order: DESC, fields: [frontmatter___date]}) {
          edges {
              node {
                  id
                  frontmatter {
                      path
                      title
                      date
                      author
                      featured_image {
                          alt
                          title
                          src {
                              childImageSharp {
                                  fluid(maxWidth: 300) {
                                      ...GatsbyImageSharpFluid
                                  }
                              }
                          }
                      }
                  }
                  excerpt(pruneLength: 500)
              }
          }
        }
      heroImage: file(relativePath: {eq: "business-computer-connection-final.png"}) {
        childImageSharp {
          fluid(maxWidth: 1200, quality: 75) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      meetingImage: file(relativePath: {eq: "american-cheerful-colleagues-final.png"}) {
        childImageSharp {
          fluid(maxWidth: 600) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      keyboardImage: file(relativePath: {eq: "abstract-backlight-black-final.png"}) {
        childImageSharp {
          fluid(maxWidth: 600) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `)

  posts.allMarkdownRemark = markdownPosts

  return (
    <Layout>
      <SEO title="Home" />
      <BackgroundImage 
        Tag="section"
        fluid={heroImage.childImageSharp.fluid}
        className="flex flex-col justify-center w-full h-screen bg-gray-100 bg-no-repeat bg-cover bg-center text-white px-6"
        >
          <h1 className="text-center text-xl md:text-5xl font-bold mb-4">C&amp;F Solutions, LLC</h1>
          <p className="text-center md:text-2xl">Software Development and IT Consulting services tailored to your business.</p>
        </BackgroundImage>

        <section className="flex mt-24 justify-between mx-6 sm:mx-12">
          <Img fluid={meetingImage.childImageSharp.fluid} alt="Meeting" className="hidden my-auto md:block md:w-1/2 mr-6 rounded-lg"/>
          <article className="md:w-1/2 md:ml-6">
            <h2 className="text-xl lg:text-2xl font-bold sm:font-semibold text-center mb-4">It’s about more than Technology</h2>

            <p className="text-base xs:text-xl text-gray-700">
              Even the best technology is useless if it isn’t the right choice for your organization. All too often, you are 
              presented with solutions based upon what the solution provider likes best, rather than the best fit for the your 
              needs. The key to avoiding this trap is to realize that the technology is not the goal. It is just a tool. As in 
              any endeavor, you should use the tools that you need, not the tools being hyped.
            </p>
          </article>
        </section>

        <section>
        <BackgroundImage fluid={keyboardImage.childImageSharp.fluid} tag="experience" className="mt-24 py-24 px-10 sm:px-20 text-white bg-fixed">
          <h2 className="text-center text-3xl sm:text-4xl">Broad Experience</h2>
          
          <p className="text-xl sm:text-2xl mt-12 text-center">
            We offer an end-to-end perspective when addressing your business needs. With a deep experience and understanding of 
            IT Infrastructure, Security Concerns and Best Practices, Systems Architecture, and Software Development strategies 
            are applied to every project.
          </p>
        </BackgroundImage>
        </section>

        <section className="mt-24 px-10 pb-12 sm:pb-24 sm:px-20 sm:px20 text-gray-900">
          <h2 className="text-center text-3xl font-bold">Latest Articles</h2>
          <Posts postList={posts}></Posts>
        </section>

    </Layout>
  )
}

export default IndexPage
