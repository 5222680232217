import { Link } from "gatsby"
import PropTypes from "prop-types"
import React from "react"
import Menu from "./menu"

const Header = ({ siteTitle }) => (
  <header className="my-0 px-6 py-4 text-center sm:flex items-baseline justify-between bg-white mb-0 text-gray-900">
    <div className="m-0 mb-4 sm:mb-0 text-2xl font-semibold">
      <Link to="/" className="no-underline">
        {siteTitle}
      </Link>
    </div>
    <div className="font-bold">
      <Menu></Menu>
    </div>
  </header>
)

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
